@import "../variables.scss";

@mixin mat-button-toggle-attribute($attribute) {
  .mat-button-toggle-group {
    &[#{$attribute}] {
      .mat-button-toggle {
        @content;
      }
    }
  }
}

////  STYLE  ////////////////
.mat-button-toggle-group[flat] {
  box-shadow: none;
}

////  COLORS  ////////////////

@include mat-button-toggle-attribute('color="light"') {
  background-color: $light;
  color: $dark;
  border: 1px solid $dark;

  &.mat-button-toggle-checked {
    background-color: $dark;
    color: $light;
  }
}


@include mat-button-toggle-attribute('color="dark"') {
  background-color: $dark;
  color: $light;

  &.mat-button-toggle-checked {
    background-color: $light;
    color: $dark;
  }
}


@include mat-button-toggle-attribute('color="primary"') {
  background-color: $primary;
  color: $light;

  &.mat-button-toggle-checked {
    background-color: darken($primary, 20%);
    color: $white;
  }
}


@include mat-button-toggle-attribute('color="accent"') {
  background-color: $accent;
  color: $light;

  &.mat-button-toggle-checked {
    background-color: darken($accent, 20%);
  }
}


////  SIZES  ////////////////
@include mat-button-toggle-attribute('size="small"') {
  .mat-button-toggle-label-content {
    font-size: 0.8em;
    line-height: 25px;

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
}


@include mat-button-toggle-attribute('size="smaller"') {
  line-height: 13px;

  .mat-button-toggle-label-content {
    font-size: 0.6em;
    line-height: 20px;
    padding: 0 10px;

    .mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }
}
