// Creates a minimal form field (no border or padding)
// Usage: <mat-form-field class="low-profile">
mat-form-field.low-profile {
  .mat-form-field-infix {
    border: 0;
    padding: 0;
  }
}

// bump up the size of the form field label font a tiny bit
.mat-form-field-wrapper {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      font-size: 1.1em;
    }
  }
}
