.mat-drawer, .mat-drawer-fake.opened {
  width: 300px;

  @include mobile-only {
    width: 100%;
  }
}

.mat-drawer-fake {
  position: absolute;
  width: 53px;
  height: 50px;
  overflow: hidden;
  background: $joe-red;
  transition: width 0.3s;
  display: flex;
  align-items: center;
}

.mat-drawer-container {
  background: white;
}
