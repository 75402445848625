@import "../variables.scss";

////  COLORS  ////////////////
.mat-raised-button, .mat-flat-button {
  &[color="light"] {
    background-color: $light;
    color: $dark;
  }

  &[color="dark"] {
    background-color: $dark;
    color: $light;
  }
}

.mat-stroked-button {
  &[color="light"] {
    border-color: $light;
    color: $light;
  }

  &[color="dark"] {
    border-color: $dark;
    color: $dark;
  }
}

.mat-icon-button {
  &[color="light"] {
    color: $light;
  }

  &[color="dark"] {
    color: $dark;
  }
}

////  SIZES  ////////////////
.mat-button, .mat-raised-button, .mat-stroked-button, .mat-flat-button {
  &[size="small"] {
    font-size: 0.75em;
    line-height: 2.5em;
    padding: 0 10px;

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 16px;
    }
  }

  &[size="smaller"] {
    font-size: 0.6em;
    line-height: 2.3em;
    padding: 0 6px;
    min-width: 50px;

    .mat-icon {
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }
}

.mat-icon-button {
  &[size="small"] {
    width: auto;
    height: 20px;
    line-height: 20px;

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }

  &[size="smaller"] {
    width: auto;
    height: 14px;
    line-height: 14px;

    .mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }
}
