@mixin desktop-only() {
  @media screen and (min-width: 960px) {
      @content;
  }
}

@mixin mobile-only() {
  @media screen and (max-width: 959px) {
      @content;
  }
}