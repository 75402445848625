// VERMILLION - (#E21E1A)
$joe-vermillion: (
  50: #fce9e7,
  100: #f8c8c2,
  200: #f4a499,
  300: #f08070,
  400: #ec6452,
  500: #e21e1a,
  600: #e21e1a,
  700: #e21e1a,
  800: #e21e1a,
  900: #e21e1a,
  A100: #ffffff,
  A200: #ffd8d6,
  A400: #ffa8a3,
  A700: #ff908a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// COSMIC LATTE (#fffae2)
$joe-cosmiclatte: (
  50: #fffefc,
  100: #fffef6,
  200: #fffdf1,
  300: #fffceb,
  400: #fffbe6,
  500: #fffae2,
  600: #fff9df,
  700: #fff9da,
  800: #fff8d6,
  900: #fff6cf,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// BROWN YELLOW (#cd925f)
$joe-brownyellow: (
  50: #f9f2ec,
  100: #f0decf,
  200: #e6c9af,
  300: #dcb38f,
  400: #d5a277,
  500: #cd925f,
  600: #c88a57,
  700: #c17f4d,
  800: #ba7543,
  900: #ae6332,
  A100: #fff9f6,
  A200: #ffd9c3,
  A400: #ffb990,
  A700: #ffa976,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// COCONUT (#8c5b2e)
$joe-coconut: (
  50: #f1ebe6,
  100: #ddcec0,
  200: #c6ad97,
  300: #af8c6d,
  400: #9d744d,
  500: #8c5b2e,
  600: #845329,
  700: #794923,
  800: #6f401d,
  900: #5c2f12,
  A100: #ffba94,
  A200: #ff9961,
  A400: #ff782e,
  A700: #ff6714,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// MIDDLE BLUE GREEN (#7cd6d1)
$joe-middlebluegreen: (
  50: #effaf9,
  100: #d8f3f1,
  200: #beebe8,
  300: #a3e2df,
  400: #90dcd8,
  500: #7cd6d1,
  600: #74d1cc,
  700: #69ccc6,
  800: #5fc6c0,
  900: #4cbcb5,
  A100: #ffffff,
  A200: #e5fffd,
  A400: #b2fffa,
  A700: #98fff8,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$joe-red-palette: mat-palette($joe-vermillion);
$joe-light-palette: mat-palette($joe-cosmiclatte);
$joe-tan-palette: mat-palette($joe-brownyellow);
$joe-brown-palette: mat-palette($joe-coconut);
$joe-blue-palette: mat-palette($joe-middlebluegreen);
