// fxLayoutGap does not work well with wrapped rows
// instead use these classes to add gutters

// 5 items per row
// class: layout-gap-wrap-5
// child width: fxFlex="19.2"

// 4 items per row
// class: layout-gap-wrap-4
// child width: fxFlex="24.25"

// 3 items per row
// class: layout-gap-wrap-3
// child width: fxFlex="32.666"

// 2 items per row
// class: layout-gap-wrap-2
// child width: fxFlex="49.5"

[fxLayout].layout-gap-wrap-5 > :not(:nth-child(5n)),
[fxLayout].layout-gap-wrap-4 > :not(:nth-child(4n)),
[fxLayout].layout-gap-wrap-3 > :not(:nth-child(3n)),
[fxLayout].layout-gap-wrap-2 > :not(:nth-child(2n)) {
  margin: 0 1% 1% 0;
}

// sets bottom margin for responsive (full width) elements
[fxLayout].layout-gap-bottom > * {
  margin-bottom: 1%;
}
