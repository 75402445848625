@import "../variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba($black, 0);
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: $font-muli-regular;
  overflow: hidden;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
