@import "../theme/joe-theme-variables";

// themed coloring
$primary: mat-color($joe-app-primary);
$accent: mat-color($joe-app-accent);
$warn: mat-color($joe-app-warn);

$primary-opaque-light: rgba($primary, 0.4);
$primary-opaque: rgba($primary, 0.4);
$primary-opaque-dark: rgba($primary, 0.4);

//shadowing
$box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
$overlay-light: rgba(0, 0, 0, 0.2);
$overlay-dark: rgba(0, 0, 0, 0.5);
$overlay-darker: rgba(0, 0, 0, 0.7);
$text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);

//additional coloring
$light: $joe-light;
$medium: $joe-tan;
$dark: $joe-brown;
$white: #FFF;
$black: #000;
$green: rgba(50, 150, 50, 0.7);

$yes-green: #44CC44;
$no-red: #DD5555;

$clover-green: #30881c;
