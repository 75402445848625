mat-progress-bar[color="white"] {
  .mat-progress-bar.mat-accent .mat-progress-bar-background {
    fill: $white;
  }

  .mat-progress-bar-fill::after {
    background-color: $white;
  }
}

mat-progress-bar[color="dark"] {
  .mat-progress-bar.mat-accent .mat-progress-bar-background {
    fill: $dark;
  }

  .mat-progress-bar-fill::after {
    background-color: $dark;
  }
}

mat-progress-bar[color="light"] {
  .mat-progress-bar.mat-accent .mat-progress-bar-background {
    fill: $light;
  }

  .mat-progress-bar-fill::after {
    background-color: $light;
  }
}
