// Creates a dialog without a background
// Usage: this.dialog.open(SomeComponent, { panelClass: 'transparent-dialog' });
.transparent-dialog {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
  }
}

// Creates a dialog without "bleed" (no padding)
// Usage: this.dialog.open(SomeComponent, { panelClass: 'no-bleed-dialog' });
.no-bleed-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.mat-dialog-container .mat-dialog-content {
  overflow-x: hidden;
}
